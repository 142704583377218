import { api } from '@shared/config/axios';

export default {
  getList(url = null, params) {
    if (url && url.startsWith('http')) {
      return api.$get(url, { params });
    }

    return api.$get('/community/abuses', { params });
  },
  delete(uuid) {
    return api.$delete(`/community/abuses/${uuid}`);
  },
};
